import { GetAppManifestFn } from '@wix/yoshi-flow-editor';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';
import { PagesManifest } from '@wix/editor-platform-sdk-types';

import { AppDescriptorAction } from './appDescriptor';
import { GROUP_WIDGET_ID, GROUPS_WIDGET_ID } from '../config/constants';

const LEARN_MORE_KB_ID = '57cfed92-b0db-435e-8872-03b6637bd2e6';

/**
 * https://bo.wix.com/wix-docs/fe-guild/editor-platform/app-manifest-reference/app-manifest
 * https://github.com/wix-private/editor-platform/blob/master/packages/platform-editor-sdk/docs/articles/manifest-builder/manifest-builder.md
 */
export const getAppManifest: GetAppManifestFn = async (
  options,
  sdk,
  context,
  flowApi,
) => {
  const { appManifestBuilder } = options;
  const { t } = flowApi.translations;

  return appManifestBuilder
    .withJsonManifest({
      pages: {
        pageActions: {
          default: [
            {
              type: 'page_rename',
            },
            {
              type: 'page_delete',
            },
          ],
          groupPage: [],
        },
        pageSettings: {
          default: [
            {
              title: t('groups-web.page.info'),
              type: 'page_info',
            },
            {
              title: t('groups-web.page.layout'),
              type: 'layout',
            },
            {
              title: t('groups-web.page.permissions'),
              type: 'permissions',
            },
            {
              title: t('groups-web.page.seo'),
              type: 'seo',
            },
          ],
          groupPage: [
            {
              title: t('groups-web.page.info'),
              type: 'page_info',
              url: getPanelUrl('Group', 'PageInfo'),
            },
            {
              title: t('groups-web.page.layout'),
              type: 'layout',
            },
            {
              title: t('groups-web.page.permissions'),
              type: 'permissions',
            },
          ],
        } as PagesManifest['pageSettings'],
        applicationSettings: {
          default: {
            displayName: t('groups-web.groups-pages-tab-name'),
          },
        },
        applicationActions: {
          default: [],
        },
      },
    })
    .configureManagementActions((builder) => {
      builder.learnMoreAction().set({ id: LEARN_MORE_KB_ID });

      builder.mainActions().addAction({
        actionId: AppDescriptorAction.MAIN_ACTION,
        icon: 'appManager_settingsAction',
        title: t('groups-web.manage.actions.main'),
      });

      builder
        .customActions()
        .addAction({
          title: t('groups-web.manage.actions.create.group'),
          actionId: AppDescriptorAction.CREATE_GROUP,
          icon: 'appManager_relatedAppsAction',
          type: 'editorActions',
        })
        .addAction({
          title: t('groups-web.manage.actions.add.widget'),
          actionId: AppDescriptorAction.ADD_WIDGET,
          icon: 'appManager_addElementsAction',
          type: 'editorActions',
        })
        .addAction({
          title: t('groups-web.manage.actions.setup'),
          actionId: AppDescriptorAction.SETUP,
          icon: 'appManager_settingsAction',
          type: 'editorActions',
        });
    })
    .configureController(GROUPS_WIDGET_ID, (builder) => {
      builder.configureConnectedComponents((builder) => {
        builder.set({
          displayName: t('groups-web.initial.page-name.groups-page'),
        });
      });
    })
    .configureController(GROUP_WIDGET_ID, (builder) => {
      builder.configureConnectedComponents((builder) => {
        builder.set({
          displayName: t('groups-web.initial.page-name.group-page'),
        });
      });
    })
    .build();
};
